import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, Input, OnInit, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterUrlList } from 'src/app/models/url-list';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/global/services/app-config/app-config.service';
import { ProjConfigService } from 'src/app/global/services/proj-config/proj-config.service';

@Component({
  selector: 'pro-button',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule
  ],
  host: {
    "(click)": "onClick($event)"
  },
  templateUrl: './pro-button.component.html',
  styleUrls: ['./pro-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProButtonComponent implements OnInit {

  showSubtitle = input(true);

  private appConfig = inject(AppConfigService);
  private projConfig = inject(ProjConfigService);

  private router = inject(Router);

  showAdScreen = signal(false);
  subtitle = signal(this.projConfig.stringId?.proButtonSubtitle);
  title = signal(this.projConfig.stringId?.proButtonTitle);

  ngOnInit(): void {
    this.appConfig.showAdScreen.subscribe(
      show => this.showAdScreen.set(show),
      error => console.error(error)
    )

  }

  onClick(event: MouseEvent): void {
    this.router.navigate([RouterUrlList.AD_SCREEN]);
  }
}
